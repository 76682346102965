#root {
    background-color: #f8f8f8;
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    color: #1b1b1e;
    letter-spacing: normal;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    cursor: default;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main {
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 0px;
    transition-duration: .2s;
}
 .main {
     position: relative;
     background-color: #ffffff;
     overflow: hidden;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     min-height: 100vh;
     min-width: 320px;
     margin: 0 auto;
 }
.main-wrapper {
    padding: 0px;
}
 .main-wrapper {
     flex: 1 1;
     position: relative;
     overflow: hidden;
     display: flex;
     flex-direction: column;
 }
.lk-load {
    position: relative;
    background-color: #7b5c9b;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    color: #ffffff;
    /*margin: -59px 0 0;*/
}
.lk-load .inner-load {
    text-align: center;
}
.brand-load {
    display: block;
    width: 129px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}
img {
     vertical-align: middle;
     border-style: none;
 }
.lk-load .title-load {
    display: block;
    font-size: 24px;
    margin: 30px 0 0;
}
body {
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    -webkit-user-select: none;
    position: relative;
    width: 100%;
    height: 100%;
    overscroll-behavior-x: none!important;
    overscroll-behavior-y: none!important;
}
.btn-primary-animate-content {
    background-size: 8px 8px;
    -webkit-animation: progress-bar 2s linear infinite;
    animation: progress-bar 2s linear infinite;
    background-image: -webkit-linear-gradient(45deg,hsla(0,0%,50.2%,.2) 12.5%,transparent 0,transparent 50%,hsla(0,0%,50.2%,.2) 0,hsla(0,0%,50.2%,.2) 62.5%,transparent 0);
    background-image: linear-gradient(45deg,hsla(0,0%,50.2%,.2) 12.5%,transparent 0,transparent 50%,hsla(0,0%,50.2%,.2) 0,hsla(0,0%,50.2%,.2) 62.5%,transparent 0);
}
@-webkit-keyframes progress-bar{0%{background-position:0 0}to{background-position:32px 0}}
@keyframes progress-bar{0%{background-position:0 0}to{background-position:32px 0}}

.lk-load-name {
    position: absolute;
    top: 80%;
    font-size: 20px;
}
