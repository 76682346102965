.button {
    color: black;
}

.button_active {
    color: #1c82ea;
}
.scrollTap {
    width: 10px;
    height: 10px;
    background-color: blueviolet;
}