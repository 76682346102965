body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
}
.floatScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 60px 0 0px;
    font-family: LikePro,Open Sans,Helvetica Neue,sans-serif;
    overflow: hidden;
    overflow-y: scroll;
    background-image: linear-gradient(to right, #8cbde6 0%, #a160a8 100%);
}
.floatScreen:before {
    content: '';

    display: block;
    position: absolute;
    opacity: .25;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}



.auth {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    width: 100%;
    max-width: 490px;
    min-height: 100%;

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.logo_white {
    background: transparent url(../../media/login/image/logo-white.svg) no-repeat 50% 50%;
    display: block;
    margin: 0 auto 25px;
    width: 64px;
    height: 57px;
}
.title1 {
    margin: 0 0 30px;
    color: #fff;
    font-size: 28px;
    letter-spacing: -1px;
    text-align: center;

    line-height: 1.1;

    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}


.form_group {
    margin-bottom: 17px;
}
.form_field {
    position: relative;
    margin-bottom: 30px;
}
.form_field:first_child input {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.form_group_input {

    background-color: #ffffff;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0 48px 0 27px;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
    outline: none;
    color: #2e2734;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.input-label1 {
    display: block;
    color: #fff;
    position: relative;
    margin: 0 0 10px;
}
.w-100 {
    width: 100%;
}
.view-22 {
    background-color: #fff;
    border: 1px solid #f0eff3;
}
.form-control {
    background-color: #fff!important;
    display: block;
    width: 100%;
    height: auto;
    font-weight: 400;
    letter-spacing: normal;
    line-height: normal;
    color: #1b1b1e;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: none;
    padding: 12px 20px 12px;
    font-size: 17px;
    overflow: visible;
    margin: 0;
    font-family: inherit;
}
.form-control:focus {
    border: 1px solid #000;
    box-sizing: inherit;
}
.input-box {
    margin: 15px 0 0 0;
}
input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;

    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 11px system-ui;
    padding: 1px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
    outline:none;
    background-color: #000;
}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    -webkit-text-fill-color: #999 !important; /* цвет текста */
    color: #000 !important; /* цвет текста */
    font-size: 17px !important;
}
.btn-primary1.border1 {
    color: #fff;
    background-color: #63487f;
    border-color: #63487f;
}
.btn1:not(.btn-block) {
    display: inline-block;
}

.btn1 {
    font-weight: 400;
    color: transparent;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    letter-spacing: normal;
    line-height: normal;
    border-radius: 4px;
    font-size: 17px;
    padding: 12px 30px 12px;

}
.btn-primary1:hover {
    border-color: #7b5c9b;
}
.input-box-info {
    font-size: 15px;
}
.input-box-info a {
    color: #fff;
    text-decoration: underline;
}
.input-box-info a:hover {
    color: #fff;
    text-decoration: none;
}
.re-pass-box {
    float: right;
    line-height: 46px;
}
.re-pass-box a {
    color: #fff;
    text-decoration: underline;
}
.re-pass-box a:hover {
    color: #fff;
    text-decoration: none;
}
.box-info-reg {
    border-top: 1px solid #63487f;
    padding-top: 10px;
}
.box-info-reg-info {
    color: #fff;
    font-size: 14px;
}
.box-info-reg-button a {
    color: #fff;
    text-decoration: underline;
}
.box-info-reg-button a:hover {
    color: #fff;
    text-decoration: none;
}

.informError {
    margin: 12px 0 0 0;
}

.add-publication__button .group-settings {
    display: flex;
    flex-wrap: wrap;
    margin: -16px -8px;

}

.text-box-pass-box {
    margin: -35px 10px 0 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    background-image: url("../../media/img/icons/passLook.png");
    width: 25px;
    height: 25px;
    opacity: 0.4;
    background-size: cover;

}
.text-box-pass-box:hover {
    opacity: 0.8;
}
.text-box-pass-box-true {
    opacity: 1!important;
}
.type-button-cursor-default {
    cursor: default!important;
}
.inputDefoult {
    position: relative;
}
.btn-primary-animate {
    background-size: 8px 8px;
    -webkit-animation: progress-bar 2s linear infinite;
    animation: progress-bar 2s linear infinite;
    background-image: -webkit-linear-gradient(45deg,hsla(0,0%,50.2%,.5) 12.5%,transparent 0,transparent 50%,hsla(0,0%,50.2%,.5) 0,hsla(0,0%,50.2%,.5) 62.5%,transparent 0)!important;
    background-image: linear-gradient(45deg,hsla(0,0%,50.2%,.5) 12.5%,transparent 0,transparent 50%,hsla(0,0%,50.2%,.5) 0,hsla(0,0%,50.2%,.5) 62.5%,transparent 0)!important;
    background-color: #63487f!important;
    cursor: default!important;
    color: #fff!important;
    background-repeat: repeat!important;
}
@-webkit-keyframes progress-bar{0%{background-position:0 0}to{background-position:32px 0}}
@keyframes progress-bar{0%{background-position:0 0}to{background-position:32px 0}}
.icon-user-error {
    position: absolute!important;
    left: 200px!important;
    top: 8px!important;
    margin-right: 30px!important;
}
/*Анимация для фона при загрузке*/
.btn-primary-animate-content {
    background-size: 8px 8px;
    -webkit-animation: progress-bar 2s linear infinite;
    animation: progress-bar 2s linear infinite;
    background-image: -webkit-linear-gradient(45deg,hsla(0,0%,50.2%,.2) 12.5%,transparent 0,transparent 50%,hsla(0,0%,50.2%,.2) 0,hsla(0,0%,50.2%,.2) 62.5%,transparent 0);
    background-image: linear-gradient(45deg,hsla(0,0%,50.2%,.2) 12.5%,transparent 0,transparent 50%,hsla(0,0%,50.2%,.2) 0,hsla(0,0%,50.2%,.2) 62.5%,transparent 0);
}

.logo_white_animation {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    transform: scale(1, 1);
}
.logo_white_animation_finish {
    transform: scale(0, 0);
    height: 0px;
    opacity: 0;
}
