.icon {
    display: inline-block;
    font-size: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: 100% 100%;
}

.icon:not(.icon-abs) {
    position: relative;
}

.icon-abs {
    position: absolute;
}


.icon-calendar {
    width: 20px;
    height: 20px;
    background-image: url("../img/icons/calendar.svg");
}

.icon-calendar-clear {
    width: 20px;
    height: 20px;
    background-image: url("../img/icons/calendar-clear.svg");
}

.icon-share {
    width: 14px;
    height: 12px;
    background-image: url("../img/icons/share.svg");
}

.icon-user-error {
    width: 16px;
    height: 14px;
    background-image: url("../img/icons/users/error.svg");
}

.icon-user-message {
    width: 16px;
    height: 17px;
    background-image: url("../img/icons/users/message.svg");
}

.icon-user-yes {
    width: 20px;
    height: 15px;
    background-image: url("../img/icons/users/check-copy-4.svg");
}

.icon-nav-add {
    width: 21px;
    height: 21px;
    background-image: url("../img/icons/nav/icons-8-add.svg");
}

.icon-nav-save {
    width: 21px;
    height: 21px;
    background-image: url("../img/icons/nav/icons-8-save-all.svg");
}

.icon-nav-download {
    width: 21px;
    height: 14px;
    background-image: url("../img/icons/nav/icons-8-download-from-the-cloud.svg");
}

.icon-nav-about {
    width: 21px;
    height: 21px;
    background-image: url("../img/icons/nav/icons-8-about-copy.svg");
}

.icon-nav-settings {
    width: 21px;
    height: 22px;
    background-image: url("../img/icons/nav/settings.svg");
}

.icon-nav-tarrifs {
    width: 21px;
    height: 22px;
    background-image: url("../img/icons/nav/tarrifs.svg");
}

.icon-nav-exit {
    width: 23px;
    height: 22px;
    background-image: url("../img/icons/nav/exit.svg");
}

.icon-control-arrow {
    width: 18px;
    height: 10px;
    background-image: url("../img/icons/sidebar/control/arrow.svg");
}

.icon-control-plus {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-plas.svg");
}

.icon-control-plus-story {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-story.svg");
}
.icon-control-plus-mobile {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-post.svg");
}
.icon-control-plus-igtv {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-igtv.svg");
}
.icon-control-plus-reels {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-reels.svg");
}
.icon-control-plus-posts {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-posts.svg");
}
.icon-control-plus-storys {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-storys.svg");
}
.icon-control-plus-link {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-link.svg");
}
.icon-control-plus-liberty {
    width: 20px;
    height: 20px;
    background-image: url("../svg/menu/menu-create-post-liberty.svg");
}

.icon-control-yes {
    width: 20px;
    height: 15px;
    background-image: url("../img/icons/sidebar/control/check-copy-4.svg");
}

.icon-user-settings {
    width: 18px;
    height: 19px;
    background-image: url("../img/icons/sidebar/users/settings.svg");
}

.icon-post-like {
    width: 15px;
    height: 14px;
    background-image: url("../img/icons/posts/like.svg");
}

.icon-post-comment {
    width: 14px;
    height: 15px;
    background-image: url("../img/icons/posts/comment.svg");
}

.icon-post-eye {
    width: 15px;
    height: 11px;
    background-image: url("../img/icons/posts/eye.svg");
}

.icon-chat-send {
    width: 22px;
    height: 20px;
    background-image: url("../img/icons/chat/icons-8-sent.svg");
}

.icon-chat-attach {
    width: 22px;
    height: 20px;
    background-image: url("../img/icons/chat/icons-8-attach.svg");
}

.icon-chat-search {
    width: 18px;
    height: 18px;
    background-image: url("../img/icons/chat/icons-8-search.svg");
}

.icon-close-help {
    width: 20px;
    height: 20px;
    background-image: url("../img/content/help/icons-8-cancel.svg");
}

.icon-close-notif {
    width: 20px;
    height: 20px;
    background-image: url("../img/content/notif/icons-8-cancel.svg");
}

.icon-settings-move {
    width: 20px;
    height: 12px;
    background-image: url("../img/icons/settings/move-copy.svg");
}

.icon-settings-edit {
    width: 18px;
    height: 18px;
    background-image: url("../img/icons/settings/icons-8-edit.svg");
}

.icon-settings-remove {
    width: 15px;
    height: 18px;
    background-image: url("../img/icons/settings/icons-8-trash-can.svg");
}

.icon-settings-add {
    width: 20px;
    height: 15px;
    background-image: url("../img/icons/settings/check-copy-4.svg");
}

.icon-settings-copy {
    width: 18px;
    height: 18px;
    background-image: url("../img/icons/settings/icons-8-copy.svg");
}

.icon-settings-check {
    width: 20px;
    height: 15px;
    background-image: url("../img/icons/settings/check-copy-4-2.svg");
}

.icon-close-info {
    width: 20px;
    height: 20px;
    background-image: url("../img/icons/info/icons-8-cancel.svg");
}

.icon-add-post-desc {
    width: 18px;
    height: 18px;
    background-image: url("../img/icons/add-post/icons-8-copy.svg");
}

.icon-post-video {
    width: 24px;
    height: 24px;
    background-image: url("../img/icons/posts/history/camera-2.svg");
}

.icon-post-img {
    width: 24px;
    height: 21px;
    background-image: url("../img/icons/posts/history/art.svg");
}

.icon-post-igtv {
    width: 20px;
    height: 24px;
    background-image: url("../img/icons/posts/history/iconfinder-igtv-4070141.svg");
}

.icon-post-reels {
    width: 24px;
    height: 24px;
    background-image: url("../img/icons/icon-instagram-reel.svg");
}

.icon-post-time {
    width: 22px;
    height: 26px;
    background-image: url("../img/icons/posts/history/timer.svg");
}

.icon-filter-type {
    width: 4px;
    height: 20px;
    background-image: url("../img/icons/filter/group/more.svg");
}

.icon-filter-type-white {
    width: 4px;
    height: 20px;
    background-image: url("../img/icons/filter/group/more-white.svg");
}

.icon-filter-yes {
    width: 20px;
    height: 15px;
    background-image: url("../img/icons/filter/group/check-copy-4.svg");
}

.icon-filter-remove {
    width: 15px;
    height: 18px;
    background-image: url("../img/icons/filter/group/icons-8-trash-can.svg");
}

.icon-filter-add {
    width: 20px;
    height: 20px;
    background-image: url("../img/icons/filter/group/add.svg");
}

.icon-editor-add {
    width: 22px;
    height: 22px;
    background-image: url("../img/icons/editor/icons-8-add.svg");
}
