.fixedOverlay {

}

.fixedOverlayModal {
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);

    text-align: center;
    white-space: nowrap;
}

.fixedOverlayModal::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modal {
    display: inline-block;
    vertical-align: middle;
}

.modalСontainer {
    margin: 50px;
    padding: 20px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    color: #000;
}

.modalBox {
    width: 800px;
    background-color: #ffffff;
}
.modalClose {
    float: right;
    cursor: pointer;
}
.ImageGallery {
    width: 500px;
    margin: 25px auto;
}

.textBox {
    white-space: pre-line;
    margin: 10px;
    text-align: left;
}

.commentBox {
    white-space: pre-line;
    margin: 10px;
    text-align: left;
}