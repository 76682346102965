@font-face {
    font-family: fabric-mode;
    src:    url(fabric-fonts/ModeC.woff2) format('woff2'),
    url(fabric-fonts/ModeC.woff) format('woff'),
    url(fabric-fonts/ModeC.ttf) format('truetype');
}
@font-face {
    font-family: fabric-neon;
    src:    url(fabric-fonts/LavanderiaC.woff2) format('woff2'),
    url(fabric-fonts/LavanderiaC.woff) format('woff'),
    url(fabric-fonts/LavanderiaC.ttf) format('truetype');
}
@font-face {
    font-family: fabric-alaRuss;
    src:    url(fabric-fonts/ALaRuss.woff2) format('woff2'),
    url(fabric-fonts/ALaRuss.woff) format('woff'),
    url(fabric-fonts/ALaRuss.ttf) format('truetype');
}
/*--------- AvenyT ----------*/
@font-face {
    font-family: 'AvenyT';
    font-weight: normal;
    font-style: normal;
    src:
            url(fabric-fonts/AvenyT-Regular.woff2) format('woff2'),
            url(fabric-fonts/AvenyT-Regular.woff) format('woff'),
            url(fabric-fonts/AvenyT-Regular.ttf) format('ttf');
}
@font-face {
    font-family: 'AvenyT';
    font-weight: normal;
    font-style: normal;
    src:
            url(fabric-fonts/HelveticaRegular.woff2) format('woff2'),
            url(fabric-fonts/HelveticaRegular.woff) format('woff'),
            url(fabric-fonts/HelveticaRegular.ttf) format('ttf');
    unicode-range: U+0410,U+0411,U+0412,U+0413,U+0414,U+0415,U+0401,U+0416,U+0417,U+0418,U+0419,U+041A,U+041B,U+041C,U+041D,U+041E,U+041F,U+0420,U+0421,U+0422, U+0423, U+0424, U+0425, U+0426, U+0427, U+0428, U+0429, U+042A, U+042B, U+042C, U+042D, U+042E, U+042F,U+0430,U+0431,U+0432,U+0433,U+0434,U+0435,U+0451,U+0436,U+0437,U+0438,U+0439,U+043A,U+043B,U+043C,U+043D,U+043E,U+043F,U+0440,U+0441,U+0442,U+0443,U+0444,U+0445,U+0446,U+0447,U+0448,U+0449,U+044A,U+044B,U+044C,U+044D,U+044E,U+044F;
}
@font-face {
    font-family: 'HelveticaNeueCyrBold';
    font-weight: normal;
    font-style: normal;
    src:
            url(fabric-fonts/HelveticaNeueCyrBold.woff2) format('woff2'),
            url(fabric-fonts/HelveticaNeueCyrBold.woff) format('woff'),
            url(fabric-fonts/HelveticaNeueCyrBold.ttf) format('ttf');
}
@font-face {
    font-family: 'HelveticaNeueCyrMedium';
    src:
            url('fabric-fonts/HelveticaNeueCyrMedium.woff2') format('woff2'),
            url('fabric-fonts/HelveticaNeueCyrMedium.woff') format('woff'),
            url('fabric-fonts/HelveticaNeueCyrMedium.ttf') format('ttf');
}

@font-face {
    font-family: 'TimesNewRoman';
    src:
            url('fabric-fonts/TimesNewRoman/TimesNewRoman.woff2') format('woff2'),
            url('fabric-fonts/TimesNewRoman/TimesNewRoman.woff') format('woff'),
            url('fabric-fonts/TimesNewRoman/TimesNewRoman.ttf') format('ttf');
}

@font-face {
    font-family: 'YanoneKaffeesatz';
    src:
            url('fabric-fonts/YanoneKaffeesatz/YanoneKaffeesatz.woff2') format('woff2'),
            url('fabric-fonts/YanoneKaffeesatz/YanoneKaffeesatz.woff') format('woff'),
            url('fabric-fonts/YanoneKaffeesatz/YanoneKaffeesatz.ttf') format('ttf');
}

@font-face {
    font-family: 'Lobster';
    src:
            url('fabric-fonts/Lobster/Lobster.woff2') format('woff2'),
            url('fabric-fonts/Lobster/Lobster.woff') format('woff'),
            url('fabric-fonts/Lobster/Lobster.ttf') format('ttf');
}

@font-face {
    font-family: 'Comfortaa';
    src:
            url('fabric-fonts/Comfortaa/Comfortaa.woff2') format('woff2'),
            url('fabric-fonts/Comfortaa/Comfortaa.woff') format('woff'),
            url('fabric-fonts/Comfortaa/Comfortaa.ttf') format('ttf');
}

@font-face {
    font-family: 'Exo2';
    src:
            url('fabric-fonts/Exo2/Exo2.woff2') format('woff2'),
            url('fabric-fonts/Exo2/Exo2.woff') format('woff'),
            url('fabric-fonts/Exo2/Exo2.ttf') format('ttf');
}
@font-face {
    font-family: 'Exo2';
    src:
            url('fabric-fonts/Exo2/Exo2-Italic.woff2') format('woff2'),
            url('fabric-fonts/Exo2/Exo2-Italic.woff') format('woff'),
            url('fabric-fonts/Exo2/Exo2-Italic.ttf') format('ttf');
}

@font-face {
    font-family: 'Pacifico';
    src:
            url('fabric-fonts/Pacifico/Pacifico.woff2') format('woff2'),
            url('fabric-fonts/Pacifico/Pacifico.woff') format('woff'),
            url('fabric-fonts/Pacifico/Pacifico.ttf') format('ttf');
}

@font-face {
    font-family: 'Caveat';
    src:
            url('fabric-fonts/Caveat/Caveat.woff2') format('woff2'),
            url('fabric-fonts/Caveat/Caveat.woff') format('woff'),
            url('fabric-fonts/Caveat/Caveat.ttf') format('ttf');
}
@font-face {
    font-family: LikePro;
    font-weight: 100 400;
    src:    url(fonts/HelveticaNeueCyr-Roman.woff2) format('woff2'),
    url('fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
    url('fonts/HelveticaNeueCyr-Roman.ttf') format('ttf');
}
@font-face {
    font-family: LikePro;
    font-weight: 500 1000;
    src:    url(fonts/HelveticaNeueCyr-Medium.woff2) format('woff2'),
    url('fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
    url('fonts/HelveticaNeueCyr-Medium.ttf') format('ttf');
}
/*HelveticaNeueCyr-Medium*/


